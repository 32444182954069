import { ApolloProvider } from '@apollo/client'
import { Dispatch, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { UserInfoDocument } from 'src/graphql/generated/hooks'
import { useApolloClient } from 'src/hooks/useApolloClient'
import { setProfile } from 'src/store/actions/auth'

interface Props {
  token?: string
  children: React.ReactElement
}

export const ComponentWrapper: React.FC<Props> = ({ token, children }) => {
  const apolloClient = useApolloClient()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: Dispatch<any> = useDispatch()

  const getUserInfo = async () => {
    const res = await apolloClient.query({
      query: UserInfoDocument,
      variables: { token },
    })

    const userInfo = res.data?.userInfo
    const setProfileData = setProfile({
      name: userInfo?.username,
      id: userInfo?.external_id || '',
      companyName: userInfo?.companyName || '',
      isPremium: userInfo?.isPremium || false,
      isVendor: userInfo?.isVendor || false,
      vendor: userInfo?.vendor?.data?.attributes?.name || '',
    })

    dispatch(setProfileData)
  }

  useEffect(() => {
    if (token) {
      getUserInfo()
    }
  }, [token])

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
